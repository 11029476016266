import {AnimatePresence, motion} from "framer-motion";
import EventItem from "@/components/EventItem";
import LoaderCard from "@/components/LoaderCard";
import HeadingStyle from "@/components/HeadingStyle";
import Pagination from "@/components/Pagination";

export default function EventItemsGrid({ events, isLoading, isListView, currentPage, totalCount, pageSize, onPageChange, gridClassNames, showPagination, showHeading, headingText, headingClassNames, eventHeadingLevel }) {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.4 }}
            >
            {isLoading ?
                <>
                    <LoaderCard gridItems={4} color={'white'}/>
                    <LoaderCard gridItems={4} color={'white'}/>
                    <LoaderCard gridItems={4} color={'white'}/>
                </>
                :
                <>
                    {showHeading && <HeadingStyle text={`${headingText ? headingText : 'Events'}`} className={headingClassNames ? headingClassNames : null} level={1} />}
                    <ul className={`${gridClassNames}`} id="events-grid">
                        {events && events.map((event, i) =>
                            <>
                                <li key={i}>
                                    <EventItem event={event} isListView={isListView} eventHeadingLevel={eventHeadingLevel} />
                                </li>
                            </>
                        )}
                    </ul>
                    {showPagination ?
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage ? currentPage : 1}
                            totalCount={totalCount ? totalCount : events.length}
                            siblingCount={1}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            scrollTopElement={'#events-grid'}
                        />
                        : null}
                </>
            }
            </motion.div>
        </AnimatePresence>
    )
}